<template>
  <div class="contrastive-analysis">
    <iframe class="iframe" src="http://192.168.100.32:3009/#/supervision" frameborder="0"></iframe>
    <!-- <div id="an-l"></div>
    <div id="an-m"></div>
    <div id="an-r"></div> -->
  </div>
</template>

<script>
import * as maptalks from "maptalks";
export default {
  name:'contrastive-analysis',
  mounted() {
    // this.initmap()
  },
  methods: {
    initmap(){
      var map0 = new maptalks.Map('an-l', {
        center: [120.1, 35.8],
        zoom: 18,
        zoomControl : true,
        baseLayer: new maptalks.TileLayer('base', {
          urlTemplate: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          subdomains: ['a', 'b', 'c'],
        })
      });


      var map1 = new maptalks.Map('an-r', {
        center: map0.getCenter(),
        zoom: map0.getZoom(),
        draggable : false,        // disable draggble
        scrollWheelZoom : false,  // disable scroll wheel zoom
        dblClickZoom : false,     // disable doubleclick
        baseLayer: new maptalks.TileLayer('base1', {
          urlTemplate: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
          subdomains: ['a','b','c','d'],
        })
      });

      map0.on('moving moveend', function (e) {
        map1.setCenter(e.target.getCenter());
      });

      map0.on('zooming zoomend', function (e) {
        map1.setCenterAndZoom(e.target.getCenter(), e.target.getZoom());
      });

      map0.on('pitch', function (e) {
        map1.setPitch(e.target.getPitch());
      });

      map0.on('rotate', function (e) {
        map1.setBearing(e.target.getBearing());
      });

      new maptalks.control.Toolbar({
        'position': 'top-right',
        'items': [{
          item: 'move me',
          click: function () {}
        }]
      })
      .addTo(map0);

    }
  },
}
</script>

<style scoped>
.contrastive-analysis{
  height:100%;
  width:100%;
  position: relative;
  display: flex;
  
}
.iframe{
  width: 100%;
  height: 100%;
}
#an-l{
  width: 40%;
  height: 100%;
}
#an-m{
  width: 20%;
  height: 100%;
}
#an-r{
  width: 40%;
  height: 100%;
}
.attr{background-color:#34495e;color:#fff;padding:0px 4px;font:16px sans-serif}
</style>
